const apis = {
    getCmsDayLog: {
        url: "/statisticsday/selectStatisticsDayListJson",
        method: "POST"
    },
    getCmsMonthLog: {
        url: "/statisticsmonth/selectStatisticsMonthListJson",
        method: "POST"
    },
    getUcsServerAll: {
        url: "/ucsmaster/selectUcsMasterListAllJson",
        method: "POST"
    },

}

export default apis;