<template>
  <section class="mt-2">
    <b-row class="match-height">
      <!-- 장애 현황 파악 리스트 -->
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="mr-1 ml-1 mt-1 mb-0">
            <b-row>
              <!-- 타이틀 -->
              <b-col
                cols="12"
                xl="12"
                lg="12"
                md="12"
                class="clearfix d-flex"
                style="justify-content: space-between"
              >
                <h4
                  class="col-md-4 font-weight-bolder mt-8px mb-2"
                  style="padding: 0"
                >
                  일별통계
                </h4>
                <div
                  class="col-md-8 d-inline-flex"
                  style="justify-content: right; padding: 0"
                >
                  <span
                    class="display-inline-block mr-1"
                    style="line-height: 2.5"
                    >PAGE:
                  </span>
                  <b-form-select
                    style="max-width: 10%"
                    class="d-inline mr-1"
                    name="perPageSelect"
                    v-model="perPage"
                    :options="perPageOptions"
                  ></b-form-select>
                  <b-button
                    class="d-inline-block btn-sm btn-success mr-1"
                    style="max-height: 37px"
                    @click="excelDownFn(searchParam)"
                    >엑셀 다운</b-button
                  >
                  <b-button
                    class="d-inline-block btn-sm btn-primary"
                    style="max-height: 37px"
                    @click="getCmsDayLog()"
                    >검색</b-button
                  >
                </div>
              </b-col>
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix pb-2">
                <div
                  class="justify-content-center spinner-custom float-right ml-1"
                  :class="{ 'd-none': active }"
                >
                  <b-spinner
                    v-for="variant in variants"
                    :variant="variant"
                    :key="variant"
                  ></b-spinner>
                </div>
                <div class="d-flex" style="flex-direction: row-reverse">
                  <input
                    v-model="searchVo.ip"
                    class="form-control col-md-2"
                    placeholder="ip 검색"
                    @keyup.enter="getCmsDayLog()"
                  />
                  <input
                    v-model="searchVo.area"
                    class="form-control col-md-2 mr-2 ml-2"
                    placeholder="권역 검색"
                    @keyup.enter="getCmsDayLog()"
                  />
                  <flat-pickr
                    v-model="tmEnd"
                    class="form-control col-md-2"
                    :config="config"
                  />
                  <span
                    class="display-inline-block px-1"
                    style="line-height: 2.5"
                    >~</span
                  >
                  <flat-pickr
                    v-model="tm"
                    class="form-control col-md-2"
                    :config="config"
                  />
                </div>
              </b-col>
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix">
              </b-col>
              <!-- 타이틀 END -->
              <!-- 검색 박스 / 버튼 -->
            </b-row>
          </div>
          <div class="pl-1 pr-1">
            <vue-good-table
              id="cmsLogTable"
              ref="my-table"
              :columns="this.cmsDayColumns"
              :rows="this.list"
              :rtl="direction"
              v-dragscroll="{ target: '.vgt-responsive' }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: this.perPage,
                // perPageDropdown: this.perPageOptions,
              }"
              theme="my-theme"
              class="table good-table-custom table-light"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="active === true">조회된 목록이 없습니다.</span>
                <span :class="{ 'd-none': active }">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                  <b-skeleton animation="wave" width="85%"></b-skeleton>
                  <b-skeleton animation="wave" width="60%"></b-skeleton>
                  <b-skeleton animation="wave" width="93%"></b-skeleton>
                  <b-skeleton animation="wave" width="45%"></b-skeleton>
                </span>
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'sn'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
                </div>
                <div v-if="props.column.field === 'name'" class="text-nowrap">
                  {{ props.formattedRow.name }}
                </div>
                <div v-if="props.column.field === 'ip'" class="text-nowrap">
                  {{ props.formattedRow.ip }}
                </div>
                <div v-if="props.column.field === 'area'" class="text-nowrap">
                  {{ props.formattedRow.area }}
                </div>
                <div
                  v-if="props.column.field === 'averageCpu'"
                  class="text-nowrap"
                >
                  <span v-if="props.formattedRow.averageCpu">{{
                    props.formattedRow.averageCpu
                  }}</span>
                </div>
                <div v-if="props.column.field === 'maxCpu'" class="text-nowrap">
                  {{ props.formattedRow.maxCpu }}
                </div>
                <div
                  v-if="props.column.field === 'memorySize'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.memorySize }}
                </div>
                <div
                  v-if="props.column.field === 'averageMemoryPc'"
                  class="text-nowrap"
                >
                  {{
                    props.formattedRow.averageMemoryPc !== ""
                      ? Number(props.formattedRow.averageMemoryPc).toFixed(2)
                      : 0
                  }}%
                </div>
                <div
                  v-if="props.column.field === 'maxMemoryPc'"
                  class="text-nowrap"
                >
                  {{
                    props.formattedRow.maxMemoryPc !== ""
                      ? Number(props.formattedRow.maxMemoryPc).toFixed(2)
                      : 0
                  }}%
                </div>
                <div
                  v-if="
                    props.column.field === 'averageMemory' &&
                    props.column.label === '메모리 평균(GB)'
                  "
                  class="text-nowrap"
                >
                  {{
                    props.formattedRow.averageMemory != 0
                      ? Number(props.formattedRow.averageMemory).toFixed(2)
                      : 0
                  }}
                  GB
                </div>
                <div
                  v-if="props.column.field === 'maxMemory'"
                  class="text-nowrap"
                >
                  {{
                    props.formattedRow.maxMemory != 0
                      ? Number(props.formattedRow.maxMemory).toFixed(2)
                      : 0
                  }}
                  GB
                </div>

                <div
                  v-if="props.column.field === 'created'"
                  class="text-nowrap"
                >
                  {{ setDateKorea(props.formattedRow.created) }}
                </div>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-2"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<style>
.badge-critical {
  background-color: #ea5455 !important;
}
.badge-major {
  background-color: #ff9f43 !important;
}
.badge-minor {
  background-color: #ffd500 !important;
}
.badge-warning {
  background-color: #00967e !important;
}
.badge-info {
  background-color: #616161 !important;
}

.btn-primary {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
}
</style>
<script>
// import ucsData from '@/assets/api/ucs.ts'
import Ripple from "vue-ripple-directive";

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BButton,
  VBToggle,
  BSpinner,
  BSkeleton,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";

import {
  dateTimeKorean,
  excelDownLoadfnc,
  NumberPercent,
  paramString,
} from "../utils/utils.js";
import apis from "../utils/apis.js";
import columns from "../utils/columns.js";
import store from "@/store/index";

import { dragscroll } from "vue-dragscroll";

import { VueGoodTable } from "vue-good-table";

import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import xlsx from "xlsx";

export default {
  directives: {
    Ripple,
    dragscroll,
    "b-toggle": VBToggle,
  },
  components: {
    // apis,
    dateTimeKorean,
    excelDownLoadfnc,
    NumberPercent,
    paramString,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    // BAvatar,
    BSkeleton,
    VueGoodTable,
    BFormSelect,
    BButton,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    flatPickr,
    BFormDatepicker,
  },
  data() {
    return {
      ucsUrl: "ucsapi/nuova",
      setDateKorea: (date) => dateTimeKorean(date),
      excelDownFn: (data) =>
        excelDownLoadfnc(
          data,
          this.$jBack,
          apis.getCmsDayLog.url,
          apis.getCmsDayLog.method
        ),
      numP: (val, base, fixed) => NumberPercent(val, base, fixed),
      paramStrings: (param) => paramString(param),
      config: {
        enableTime: false,
        allowInput: false,
        allowInvalidPreload: false,
        //dateFormat: 'Y-m-d H:i',
        locale: Korean,
        maxDate: this.setTime(0),
        allowInput: true,
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        ariaDateFormat: "Y-m-d",
      },
      searchTerm: "",
      variants: ["primary"],
      active: true,
      perPage: 100,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      areaOption: [{ text: "전체 서버", value: 0 }],
      cmsDayColumns: columns.cmsDayColumns,
      list: [],
      tm: this.setTime(1, 1),
      tmSet: this.setTime(1, 1),
      tmEnd: this.setTime(0),
      tmEndSet: this.setTime(0),
      tmcount: 0,
      searchParam: [],
      searchVo: {
        ip: "",
        area: "",
      },
    };
  },
  watch: {
    /**
     * @author 염재헌
     * @create 2022-05-02
     * @description vue good table PerPage 외부 조작
     */
    perPage: function (val) {
      this.$refs["my-table"].perPageChanged({ currentPerPage: val });
    },
    /**
     * 시간별 data filtering (starttime)
     * @create 2021-04-15
     * @author 정한슬
     * 시간 선택 시 해당 조건에 맞는 data filtering
     */
    tm: function (val) {
      // 시작기간의 변동이 없을 시 아무 동작 안함
      if (this.tmSet === this.tm) {
      } else {
        // 시작기간의 변동이 있을 시 DB 조회
        this.setTm();
        this.getCmsDayLog();
      }
    },
    /**
     * 시간별 data filtering (endtime)
     * @create 2021-04-15
     * @author 정한슬
     * 시간 선택 시 해당 조건에 맞는 data filtering
     */
    tmEnd: function (val) {
      // 종료기간이 시작기간보다 빠를 시.
      console.log(this.tm, this.tmEnd, "::tm");
      if (this.tmEnd == this.setTime(0)) {
        this.setTm();
      } else if (this.tm > this.tmEnd && this.tmcount == 0) {
        this.tmEnd = this.setTime(0);
        this.tmcount += 1;

        alert("종료기간이 시작기간보다 빠릅니다.");
        return false;
      } else if (this.tmEndSet === this.tmEnd) {
        // 종료기간의 변동이 있을 시 DB 조회
      } else {
        // 종료기간의 변동이 있을 시 DB 조회
        this.setTm();
        this.getCmsDayLog();
      }
    },
  },
  created() {
    this.tm = this.setTime(1, 1);
    this.tmEnd = this.setTime(0);
  },
  mounted() {
    this.getCmsDayLog();
    this.setTm();
    console.log(xlsx);
  },
  methods: {
    /**
     * 검색 버튼
     * @created 2022-02-22
     * @author 김은호
     * @description 아이피, 권역, 날짜입력 바탕으로 cpu 메모리 조회
     */
    search() {
      console.log("ip", this.ip);
      console.log("area", this.area);
      console.log("tm", this.tmSet);
      console.log("tm end", this.tmEndSet);
      // this.getServLog();
    },
    getCmsDayLog() {
      this.active = false;
      let param = {
        tm: this.tm,
        tmEnd: this.tmEnd,
        recordCountPerPage: 1000,
        ip: this.searchVo.ip,
        area: this.searchVo.area,
      };
      this.searchParam = param;

      this.$jBack({
        url: apis.getCmsDayLog.url,
        method: apis.getCmsDayLog.method,
        data: this.paramStrings(param),
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((response) => {
          // console.log(response);
          this.list = response.data.resultList;
          this.active = true;
        })
        .catch((error) => {
          this.active = true;
        });
    },
    /**
     * Tm 검색 후 watch 감지용 tmSet / tmEndSet
     * @created 2021-05-20
     * @author 박재헌
     * @description 각 Tm 및 TmEnd 값 변동 없을 시 DB 검색 안되도록하기 위한 세팅
     */
    setTm() {
      this.tmSet = this.tm;
      this.tmEndSet = this.tmEnd;
      setTimeout(() => {
        this.tmcount = 0;
      }, 1000);
      // console.log(this.tmSet, this.tm)
    },
    /**
     * 페이지 로드 시 시간 갱신
     * @create 2021-04-13
     * @author 박재헌
     * 시간 갱신 setTime
     */
    setTime(h, d) {
      let time;
      const now = new Date();
      if (d === 1) {
        time = new Date(now.setDate(now.getDate() - 1));
      } else {
        time = now;
      }
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      let hour = time.getHours();
      if (time.getHours() == 0 && h == 1) {
        hour = 23;
      } else {
        hour = time.getHours() - h;
      }
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      let s = "";
      if (hour < 24) {
        // s = `${year<10 ? `0${year}`:year}-${month<10 ? `0${month}`:month}-${day<10 ? `0${day}`:day} ${hour<10 ? `0${hour}`:hour}:${minutes<10 ? `0${minutes}`:minutes}:${seconds<10 ? `0${seconds}`:seconds}`
        s = `${year < 10 ? `0${year}` : year}-${
          month < 10 ? `0${month}` : month
        }-${day < 10 ? `0${day}` : day}`;
      } else {
        const now = new Date();
        const times = new Date(now.setDate(now.getDate() - 1));
        const years = times.getFullYear();
        const months = times.getMonth() + 1;
        const days = times.getDate();
        const hours = times.getHours();
        const minutess = times.getMinutes();
        const secondss = times.getSeconds();
        // s = `${years<10 ? `0${years}`:years}-${months<10 ? `0${months}`:months}-${days<10 ? `0${days}`:days} ${hours<10 ? `0${hours}`:hours}:${minutess<10 ? `0${minutess}`:minutess}:${secondss<10 ? `0${secondss}`:secondss}`
        s = `${years < 10 ? `0${years}` : years}-${
          months < 10 ? `0${months}` : months
        }-${days < 10 ? `0${days}` : days}`;
      }
      // console.log(s)
      return s;
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss">
th,
td {
  text-align: center;
  white-space: nowrap;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
