/**
 * @autho 염재헌
 * @since 2022-05-03
 * @param {*} val 값
 * @param {*} base 백분율할 전체 값
 * @param {*} fixed 백분율 소수점 표시 자리수
 * @returns 
 * @description 백분율
 */
const NumberPercent = (val, base, fixed) => {
    const fixeds = fixed ? fixed : 2;
    const num = (Number(val).toFixed(2) / Number(base)) * 100 ? ((Number(val).toFixed(2) / Number(base)) * 100).toFixed(2) : "0"

    return num;
}


function sortFn(x, y) {
    // x - row1 value for column
    // y - row2 value for column
    // col - column being sorted
    // rowX - row object for row1
    // rowY - row object for row2
    return (x < y ? -1 : (x > y ? 1 : 0));
}

const columns = {
    cmsDayColumns: [
        {
            field: "sn",
            label: "번호",
            sortable: true,
            sortFn: sortFn,
            width: "60px",
        },
        {
            field: "name",
            label: "서버명",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "서버명",
            },
        },
        {
            field: "ip",
            label: "IP",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "IP",
            },
        },
        {
            field: "area",
            label: "권역",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "권역",
            },
            width: "80px",
        },
        {
            field: "averageCpu",
            label: "cpu 평균(%)",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "cpu 평균",
            },
        },
        {
            field: "maxCpu",
            label: "cpu 최대(%)",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "cpu 최대",
            },
        },
        {
            field: "memorySize",
            label: "메모리 크기",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "메모리 크기",
            },
        },
        {
            field: "averageMemoryPc",
            label: "메모리 평균(%)",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "메모리 평균(%)",
            },
        },
        {
            field: "maxMemoryPc",
            label: "메모리 최대(%)",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "메모리 최대(%)",
            },
        },
        {
            field: "averageMemory",
            label: "메모리 평균(GB)",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "메모리 평균(GB)",
                filterFn: (data, filterString) => {
                    return Number(data).toFixed(2).indexOf(filterString) != -1;
                }
            },
        },
        // {
        //     field: "maxMemory",
        //     label: "메모리 최대(%)",
        //     sortable: true,
        //     sortFn: sortFn,
        //     filterOptions: {
        //         enabled: true,
        //         placeholder: "메모리 최대(%)",
        //     },
        // },
        {
            field: "maxMemory",
            label: "메모리 최대(GB)",
            sortable: true,
            sortFn: sortFn,
            filterOptions: {
                enabled: true,
                placeholder: "메모리 최대(GB)",
                filterFn: (data, filterString) => {
                    return Number(data).toFixed(2).indexOf(filterString) != -1;
                }
            },
        },

        {
            field: "created",
            label: "발생시점",
            sortable: true,
            sortFn: sortFn,
        },
        // {
        //     field: "currTm",
        //     label: "등록시점",
        //     sortable: true,
        //     sortFn: sortFn,
        // },
    ],
}



export default columns;