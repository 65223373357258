import * as XLSX from "xlsx";
// import axios from "axios";

const dateTimeKorean = (date) => {
    let dates = new Date(date);
    let month = dates.getMonth() + 1;
    let day = dates.getDate();
    let year = dates.getFullYear();

    return `${year}년 ${month > 10 ? month : '0' + month}월 ${day > 10 ? day : '0' + day}일`
}

/**
 * @author 염재헌
 * @since 2022-05-03
 * @param {*} arrays 엑셀 데이터
 * @param {*} filename 엑셀명
 * @returns 다운로드
 */
const setExcelDown = (arrays, filename) => {
    var animalWS = XLSX.utils.json_to_sheet(arrays);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, animalWS, filename);
    var d = new Date();

    var month = d.getMonth() + 1;
    var day = d.getDate();

    var output = d.getFullYear() + '/' +
        (month < 10 ? '0' : '') + month + '/' +
        (day < 10 ? '0' : '') + day;

    let filenametime = output;//날짜
    XLSX.writeFile(wb, filename + "_" + filenametime + ".xlsx");
    return;
}

/**
 * @author 염재헌
 * @since 2022-05-03
 * @param {*} array 다운로드 테이블 원본 데이터
 * @param {*} filename 파일명
 * @description 다운로드할 엑셀 데이터 가공
 * CMS DAY LOG 기준으로 개조됨 원본 엑셀 다운 UTIL 은 염재헌에게 요청
 */
const setExcelDownFirst = (array, filename) => {
    // 엑셀 컬럼 키
    var searchTeam = "ip,name,area,memorySize,averageMemoryPc,maxMemoryPc,averageMemory,maxMemory,averageCpu,maxCpu,created";
    var searchTeamarray = searchTeam.split(",");
    // 엑셀 컬럼명
    var searchTeamname = "CMS 서버 IP,서버명,권역명,메모리크기,평균메모리값(%),최대메모리값(%),평균메모리값,최대메모리값,평균CPU값,최대CPU값,생성일";
    var searchTeamnamearray = searchTeamname.split(",");

    var items = [];
    var tempkey = [];
    if (array.length > 0) {
        for (var key in array[0]) {//가져온 데이터들의 key 만
            tempkey.push(key);
        }
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            var item = {};

            searchTeamarray.forEach((i, idx) => {
                tempkey.forEach((i2, idxs) => {
                    if (searchTeamarray[idx] == i2) {
                        if (i2 === 'created') {
                            var a = searchTeamnamearray[idx] !== '' ? searchTeamnamearray[idx] : '';
                            var b = element[i2] !== '' ? dateTimeKorean(element[i2]) : '';
                            item[a] = b;
                        } else if (i2 === 'averageMemoryPc' || i2 === 'maxMemoryPc') {
                            var a = searchTeamnamearray[idx] !== '' ? searchTeamnamearray[idx] : '';
                            var b = element[i2] !== '' ? Number(element[i2]).toFixed(2) : '0';
                            item[a] = b;
                        } else if (i2 === 'averageMemory' || i2 === 'maxMemory') {
                            var a = searchTeamnamearray[idx] !== '' ? searchTeamnamearray[idx] : '';
                            var b = element[i2] !== '' ? Number(element[i2]).toFixed(2) : '0';
                            item[a] = b;
                        } else {
                            var a = searchTeamnamearray[idx] !== '' ? searchTeamnamearray[idx] : '';
                            var b = element[i2] !== '' ? element[i2] : '';
                            item[a] = b;
                        }
                    }
                });
            });
            items.push(item);
        }
        return setExcelDown(items, filename);
    } else {
        return alert("출력할 데이터가 없습니다")
    }

}
/**
 * @author 염재헌
 * @since 2022-05-03
 * @param {*} param API 조회 파라미터
 * @param {*} base API 조회 BASE URL
 * @param {*} url API 조회 URL
 * @param {*} method API 조회 METHOD
 * @param {*} perPage API 조회할 갯수제한
 * @description 엑셀 다운로드 1번
 */
const excelDownLoadfnc = (param, base, url, method, perPage) => {
    param.recordCountPerPage = perPage ? perPage : 10000;

    // 주석 해제 시 10개만 엑셀에 Download
    // delete param.recordCountPerPage;

    base({
        method: method,
        url: url,
        data: paramString(param),
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }).then(function (response) {
        setExcelDownFirst(response.data.resultList, "CMS 서버 통계");
    })
        .catch(function (error) {
            if (error.response) {
                // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            } else if (error.request) {
                // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                console.log(error.request);
            } else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                console.log('Error', error);
            }
            console.log(error.config);
            alert("오류가 발생하였습니다.");
        });
}

/**
 * @author 염재헌
 * @since 2022-05-03
 * @param {*} param queryString 으로 변경할 Param
 * @returns queryString
 */
const paramString = (param) => {
    let params = Object.entries(param).map(e => e.join('=')).join('&');

    return params;
}

/**
 * @since 2021-04-01
 * @param {*} array 중복 제거할 데이터
 * @param {*} key 키
 * @returns 중복 제거된 데이터
 */
const getUniqueObjectArray = (array, key) => {
    return array.filter((item, i) => {
        return (
            array.findIndex((item2, k) => {
                return item[key] === item2[key];
            }) === i
        );
    });
}

/**
 * @autho 염재헌
 * @since 2022-05-03
 * @param {*} val 값
 * @param {*} base 백분율할 전체 값
 * @param {*} fixed 백분율 소수점 표시 자리수
 * @returns 
 * @description 백분율
 */
const NumberPercent = (val, base, fixed) => {
    const fixeds = fixed ? fixed : 2;
    const num = (Number(val).toFixed(2) / Number(base)) * 100 ? ((Number(val).toFixed(2) / Number(base)) * 100).toFixed(2) : "0"

    return num;
}


export { dateTimeKorean, excelDownLoadfnc, getUniqueObjectArray, paramString, NumberPercent }