<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
      <!-- 진행중인 미팅 조회 리스트 -->
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <!-- <b-form-select
          class="form-control col-md-2"
          v-model="perPage"
          :options="perPageOptions"
        ></b-form-select> -->
        <cmsLogListDay
          @getSn="setSn"
          :areaOption="this.areaOption"
          :items="this.callsItems"
          :active="this.active"
          @callParent="setPages"
          :total="this.total"
        ></cmsLogListDay>
      </b-col>
      <!-- 진행중인 미팅 조회 리스트 END -->
    </b-row>
  </section>
</template>
<script>
import { BRow, BCol, BFormSelect } from "bootstrap-vue";

import cmsLogListDay from "./components/cmsLogListDay.vue";

const qs = require("qs");

export default {
  components: {
    BRow,
    BCol,
    cmsLogListDay,
    BFormSelect,
  },
  data() {
    return {
      data: {},
      // callsUrl: '/api/v1/calls',
      callsUrl: "/cmscalls/selectCmsCallsListJson",
      //cmsServerUrl: '/cmsmaster/selectCmsMasterListAllJson',
      active: true,
      callsItems: [],
      offset: 1,
      total: "0",
      param: {},
      searchItem: "",
      sn: 0,
      perPage: 100,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      areaOption: [
        { value: 0, text: "권역 검색" },
        /*{ value: 1, text: '12월' },
          { value: 2, text: '12월' },
          { value: 3, text: '12월' },
          */
      ],
      search: {
        serverSn: 0,
        pageIndex: 0,
      },
    };
  },
  watch: {
    /**
     * 페이징 Index 변할 시 offset 별 API 호출
     * @created 2021-04-23
     * @author 박재헌
     * @comment 자식 컴포넌트에서 변경된 currentPage 감지하여 offset 변경 후 API 페이징인덱스로 재호출
     */
    offset() {
      // this.getCalls(this.searchItem)
    },
    /**
     * 서버 선택 시 서버별 API 호출
     * @created 2021-04-23
     * @author 박재헌
     * @comment 자식 컴포넌트에서 변경된 서버 순번 감지 후 해당 서버로 API 재호출
     */
    sn(sn) {
      // this.search.serverSn = sn
      // this.getCalls()
    },
  },
  created() {},
  mounted() {
    //this.getServer()
    //this.getCalls()
  },
  methods: {
    /**
     * 서버 선택 sn 변경
     * @created 2021-04-23
     * @author 박재헌
     * @comment 자식 컴포넌트에서 변경된 서버 순번을 세팅하기 위한 함수 getSn으로 $emit 받음
     */
    setSn(sn) {
      this.sn = sn;
      this.search.serverSn = sn;
      this.getCalls();
    },
    /**
     * CMS 서버 리스트 조회
     * @created 2021-05-18
     * @author 박재헌
     * @comment CMS 서버 목록 조회
     */
    /*getServer() {
      this.$jBack({
        method: 'post',
        url: this.cmsServerUrl,
      })
      .then(response => {
        // console.log('CMS SERVER DATA response ********** ',response)
        const serverList = response.data.resultList
        this.areaOption = [
          {value: 0, text: '전체 서버'}
        ]
        for (let index = 0; index < serverList.length; index++) {
          const element = serverList[index];
          this.areaOption.push({value: element.sn, text: element.name})
        }
      })
      .catch(error => {
        console.log(error)
      })
    },*/
    /**
     * 진행중인 미팅 정보 DB 조회
     * @create 2021-05-18
     * @author 박재헌
     * @returns {json}
     * @description 현재 진행중인 미팅 정보
     */
    getCalls() {
      this.active = false;
      this.param = {
        tbCmsMatserSn: this.search.serverSn, // CMS SERVER SN
        // pageIndex: this.search.pageIndex,
      };
      this.$jBack({
        method: "post",
        url: this.callsUrl,
        data: qs.stringify(this.param),
      })
        .then((response) => {
          if (response.data.resultList != []) {
            this.total = response.data.resultList.length.toString();
            this.callsItems = response.data.resultList;
            this.active = true;
          } else {
            this.callsItems = [];
            this.active = true;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("진행중인 미팅 목록 조회에 실패하였습니다.");
          this.active = true;
        });
    },
    // 자식에게 전달받은 페이징 넘버 세팅
    setPages(num) {
      this.offset = num;
    },
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>


